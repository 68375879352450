<template>
  <div class="detail-table t-order-detail">
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.smAndDown"
      class="table-header"
    >
      <v-col cols="6" sm="6">
        {{ $t("orders.orderDetail.header.product") }}
      </v-col>

      <v-col cols="2" sm="2" class="d-flex justify-center">
        {{ $t("orders.orderDetail.header.priceUnit") }}
      </v-col>
      <v-col
        cols="2"
        sm="2"
        class="d-flex justify-md-space-between justify-lg-space-around"
      >
        <div
          class="pr-2"
          style="width: min-content"
          v-html="$t('orders.orderDetail.header.qtyOrdered')"
          @click="clicked"
        >
          <!-- {{ $t("orders.orderDetail.header.qtyOrdered") }} -->
        </div>
        <div
          style="width: min-content"
          v-html="$t('orders.orderDetail.header.qtyPrepared')"
          @click="clicked"
        >
          <!-- {{ $t("orders.orderDetail.header.qtyPrepared") }} -->
        </div>
      </v-col>
      <!-- <v-col
        cols="2"
        sm="1"
        class="d-flex justify-center"
        v-html="$t('orders.orderDetail.header.qtyPrepared')"
      >
        {{ $t("orders.orderDetail.header.qtyPrepared") }}
      </v-col> -->
      <v-col cols="2" sm="2" class="d-flex justify-center">
        {{ $t("orders.orderDetail.header.total") }}
      </v-col>
    </v-row>

    <v-list class="t-detail-list">
      <v-list-item
        v-for="item in items"
        v-bind:key="item.itemId"
        :to="{ name: 'Product', params: { slug: item.product.slug } }"
        class="order-item"
        :class="[item.deliveredType]"
      >
        <!-- prodotto -->
        <v-row no-gutters align="center">
          <v-col
            cols="12"
            v-if="item.orderItemReplacedId"
            class="pl-16 body-2 grey--text text--lighten-1"
          >
            <v-icon>
              mdi-swap-vertical
            </v-icon>
            SOSTITUITO CON
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center mb-3 mb-md-0">
            <img
              :src="item.product.mediaURL"
              class="product-img"
              width="85px"
              height="85px"
              max-height="85px"
              max-width="85px"
            />
            <div class="product-info">
              <span class="product-name">{{ item.product.name }}</span>
              <span class="product-brand">{{ item.product.shortDescr }}</span>
              <div class="product-descr">{{ item.product.description }}</div>
              <div class="product-code">
                Cod. {{ item.product.code }}-{{ item.product.codeVariant }}
              </div>
            </div>
          </v-col>
          <!-- prezzo unitario -->
          <v-col
            cols="3"
            sm="4"
            md="2"
            class="d-flex flex-column justify-center product-info"
          >
            <span
              class="order-info-title price-title hidden-md-and-up"
              v-html="$t('orders.orderDetail.header.priceUnit')"
              @click="clicked"
            >
            </span>
            <div class="d-flex flex-column justify-center align-center">
              <span class="unit-price">
                {{ $n(item.unitPrice, "currency") }}
              </span>
              <span
                class="unit-iva"
                v-if="
                  item.product &&
                    item.product.ivaCategory &&
                    item.product.ivaCategory.descr
                "
                >{{ item.product.ivaCategory.descr }}</span
              >
            </div>
            <!-- <ProductPrice
              :product="item.product"
              :showStandard="false"
            ></ProductPrice> -->
          </v-col>
          <!-- quantità ordinata e quantità preparata -->
          <v-col
            cols="3"
            sm="2"
            md="1"
            class="d-flex flex-column justify-center align-center product-info text-center"
          >
            <span
              class="order-info-title hidden-md-and-up"
              v-html="$t('orders.orderDetail.header.qtyOrdered')"
              @click="clicked"
            >
              <!-- {{ $t("orders.orderDetail.header.qtyOrdered") }}<br /> -->
            </span>
            <OrderDetailQuantity
              :quantity="item.quantity"
              :weight="item.weight"
              :unit="getProductUnit(item.product)"
              :weightUnitDisplay="
                item.product.weightUnitDisplay
                  ? item.product.weightUnitDisplay
                  : ''
              "
              :productType="item.product.productInfos.TIPOLOGIA"
            />
          </v-col>
          <v-col
            cols="3"
            sm="2"
            md="1"
            class="d-flex flex-column justify-center align-center product-info text-center"
          >
            <span
              class="order-info-title hidden-md-and-up"
              v-html="$t('orders.orderDetail.header.qtyPrepared')"
              @click="clicked"
            >
              <!-- {{ $t("orders.orderDetail.header.qtyPrepared") }} -->
            </span>
            <OrderDetailQuantity
              :quantity="item.deliveredQuantity"
              :weight="item.deliveredWeight"
              :unit="getProductUnit(item.product)"
              :weightUnitDisplay="
                item.product.weightUnitDisplay
                  ? item.product.weightUnitDisplay
                  : ''
              "
              :productType="item.product.productInfos.TIPOLOGIA"
            />
          </v-col>
          <!-- prezzo totale -->
          <v-col
            cols="3"
            sm="4"
            md="2"
            class="d-flex flex-column justify-center product-info text-center"
          >
            <span
              class="order-info-title hidden-md-and-up"
              v-html="$t('orders.orderDetail.header.total')"
              @click="clicked"
            >
            </span>
            <strong class="gross-total">
              {{
                $n(
                  delivered ? item.deliveredGrossTotal : item.grossTotal,
                  "currency"
                )
              }}
            </strong>
          </v-col>
          <v-col
            cols="12"
            class="text-left"
            v-if="isMasterAccount && item.orderItemInfos.user_notes"
          >
            <strong>Note:</strong> {{ item.orderItemInfos.user_notes }}
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
// import ProductPrice from "@/components/product/ProductPrice.vue";
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity.vue";
import { mapGetters } from "vuex";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "OrderDetailTable",
  mixins: [clickHandler],
  props: {
    items: { required: true, type: [] },
    delivered: { required: false, default: false }
  },
  components: {
    // ProductPrice,
    OrderDetailQuantity
  },
  data() {
    return {};
  },
  methods: {
    getProductUnit(product) {
      if (product.productInfos) {
        if (product.productInfos.TIPOLOGIA == "Sfuso") {
          return product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return product.productInfos.UNIT_SELLING != null
            ? product.productInfos.UNIT_SELLING
            : "pz";
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isMasterAccount: "cart/isMasterAccount"
    })
  },
  mounted() {
    // this.fetchCommentOrder();
  }
};
</script>
