<template functional>
  <div
    class="qty-box val-cnt d-flex flex-column"
    v-if="props.productType == 'Sfuso'"
  >
    <span class="val">{{ props.weight }} {{ props.weightUnitDisplay }}</span>
  </div>
  <div class="qty-box val-cnt d-flex flex-column" v-else>
    <span class="val">{{ props.quantity }} {{ props.unit }} <br /></span>
    <span class="small" v-if="props.weight > 0"
      >({{ props.weight }} {{ props.weightUnitDisplay }})</span
    >
  </div>
</template>
<script>
export default {
  name: "props.OrderDetailQuantity",
  props: {
    quantity: { type: Number, required: true },
    weight: { type: Number, required: true },
    unit: { type: String, required: true },
    weightUnitDisplay: { type: String, required: true },
    productType: { type: String, required: true }
  }
};
</script>

<style></style>
